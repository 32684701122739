import React, { useEffect, useMemo, useState } from "react";
import { useCss, k } from "kremling";
import { DateTime } from "luxon";
import { CpTooltip, CpButton, CpLoader } from "canopy-styleguide!sofe";
import canopyUrls from "canopy-urls!sofe";
import { forceBustCache } from "fetcher!sofe";
import { successToast } from "toast-service!sofe";

import { CollaboratorPicker } from "../../../common/collaborator-picker.component";
import { DatePicker } from "../../../common/date-picker.component";
import { ChangeStatus } from "../../../common/change-status.component";
import { taxResServiceSlugs } from "../../../common/resolution-cases.helpers";
import {
  getClientData,
  updateEnvelope,
  updateProgramSection,
} from "../../../common/resolution-cases.resource";
import { catchError } from "auto-trace";
import { tryRestart } from "../assistant-utils";

export function AssistantHeader({
  children,
  canRestart,
  restartAssistant,
  params,
  section,
  fullSection,
  program,
  resolutionCase,
  sectionEnvelope,
  updateState,
  loading,
}) {
  const scope = useCss(css);
  const [activeTeamMembers, setActiveTeamMembers] = useState([]);
  const [dueDate, setDueDate] = useState(sectionEnvelope.due_at);

  useEffect(() => {
    const sub = getActiveTeamMembers();
    return () => {
      sub.unsubscribe();
    };
  }, []);

  const updateAssignee = (assignee) => {
    if (sectionEnvelope) {
      const nextSectionEnvelope = {
        ...sectionEnvelope,
        relationships: {
          ...sectionEnvelope.relationships,
          assigned_to: { type: "user", id: assignee?.id || null },
        },
      };
      updateEnvelope(
        params.clientId,
        resolutionCase.id,
        section.id,
        sectionEnvelope.id,
        nextSectionEnvelope
      ).subscribe(() => {
        if (assignee) {
          nextSectionEnvelope.relationships.assigned_to = {
            id: assignee.id,
            name: assignee.name,
            type: "user",
          };
        }
        updateState({ sectionEnvelope: nextSectionEnvelope });
      });
    }
  };

  const getActiveTeamMembers = () => {
    return getClientData(params.clientId).subscribe(({ users }) => {
      const _activeTeamMembers = users.filter(
        ({ is_activated, is_deleted, role }) => {
          return is_activated && !is_deleted && role === "TeamMember";
        }
      );
      setActiveTeamMembers(_activeTeamMembers);
    });
  };

  const requiredPermission = taxResServiceSlugs.some(
    (slug) => slug === program.slug
  )
    ? "tax_resolution_services"
    : "custom_services";

  const updateProgram = async (newValues, notifications) => {
    const errorContext = catchError();
    try {
      await updateProgramSection(
        params.clientId,
        resolutionCase.id,
        section.slug,
        {
          ...section,
          ...newValues,
        },
        notifications
      );
      forceBustCache(
        `${canopyUrls.getWorkflowUrl()}/api/clients/${
          params.clientId
        }/resolution-cases/${resolutionCase.id}`
      );
    } catch (err) {
      errorContext(err);
    }
  };

  const updateStatus = (status, notifications) => {
    updateProgram({ status }, notifications).then(() => {
      successToast("Status changed successfully.");
    }, catchError());
  };

  const openInviteNewTeamMember = () => {
    SystemJS.import("invite-team-member!sofe")
      .then(({ inviteTeamMember }) =>
        inviteTeamMember({
          closeCallback: () => getActiveTeamMembers(),
          clientId: Number(params.clientId),
        })
      )
      .catch(catchError());
  };

  const handleDateChange = (date) => {
    const dueAt = DateTime.fromJSDate(date);
    const nextSectionEnvelope = {
      ...sectionEnvelope,
      due_at: dueAt.toMillis(),
    };
    updateEnvelope(
      params.clientId,
      resolutionCase.id,
      fullSection.id,
      sectionEnvelope.id,
      nextSectionEnvelope
    ).subscribe(() => {
      updateState({
        sectionEnvelope: nextSectionEnvelope,
      });
      setDueDate(dueAt.toISO());
    }, catchError());
  };

  const changeStatusActiveTeamMembers = useMemo(() => {
    const assignedUser = sectionEnvelope?.relationships?.assigned_to;
    if (assignedUser) {
      return [activeTeamMembers.find((u) => u.id === assignedUser.id)].filter(
        Boolean
      );
    }
    return [];
  }, [sectionEnvelope, activeTeamMembers]);

  return (
    <div
      {...scope}
      className="cps-slat-lg +noclick cp-assistant__slat--no-background cps-flexible-focus"
    >
      <div className="cps-slat-lg__content">
        <div className="cps-slat-lg__content__title cps-subheader">
          {children}
        </div>
      </div>
      <div className="cps-slat-lg__actions">
        <div className="assistant-actions">
          {loading && (
            <div className="cp-mr-16">
              <CpLoader />
            </div>
          )}
          {canRestart && (
            <CpTooltip text="Restart assistant">
              <CpButton
                icon="af-refresh"
                aria-label="Restart assistant"
                onClick={() => tryRestart(restartAssistant)}
              />
            </CpTooltip>
          )}
          <CollaboratorPicker
            assignee={sectionEnvelope?.relationships.assigned_to}
            activeTeamMembers={activeTeamMembers}
            onSelect={updateAssignee}
            onRemove={() => updateAssignee(null)}
            openInviteNewTeamMember={openInviteNewTeamMember}
            requiredPermission={requiredPermission}
          />
          <DatePicker onChange={handleDateChange} value={dueDate} />
          <ChangeStatus
            status={section.status}
            onChange={updateStatus}
            activeTeamMembers={changeStatusActiveTeamMembers}
            requiredPermission={requiredPermission}
          />
        </div>
      </div>
    </div>
  );
}

const css = k`
  .assistant-actions {
    display: flex;
    gap: .8rem;
  }

`;
